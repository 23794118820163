<form *ngIf="componentReady(); else elseTemplate" [formGroup]="formGroup">
    <!-- ENTITY ELEMENT INFORMATION -->
    <app-form-header class="text-center"
        [header]="compiling ? 'entities.management.element_values' : 'entities.management.element_properties'"
        [subHeader]="compiling ? 'instances.management.entity_compiling' : 'form.required_note'"></app-form-header>
    <ng-container [ngTemplateOutlet]="compiling ? elementCompiling : elementCreation"></ng-container>
</form>

<!-- ENTITY ELEMENT CREATION (ENTITY) -->
<ng-template #elementCreation>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.name"
                [label]="('form.name' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.name)">
                    <div *ngIf="formGroup.get('name').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.label"
                [label]="('form.label' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.label)">
                    <div *ngIf="formGroup.get('label').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.order"
                [label]="('form.order' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="0"
                [max]="100">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.order)">
                    <div *ngIf="formGroup.get('order').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-select [control]="formGroup.controls.entity"
                [label]="('form.entity' | translate) + ' *'"
                [itemsList]="multiselectItems.entities"
                [readonly]="viewMode"
                (onSelect)="loadEntityProperties()">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.entity)">
                    <div *ngIf="formGroup.get('entity').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-select>
        </div>
    </div>

    <!-- ENTITY ATTRIBUTES MANAGEMENT -->
    <ng-container *ngIf="formGroup.get('relationship').value === 'M'">
        <app-form-header class="text-center mt-4"
            [header]="'elements.list_simple.entity_management'"
            [subHeader]="'elements.list_simple.entity_management_subheader'">
        </app-form-header>
        <div class="row d-flex justify-content-center">
            <div class="col-lg-4">
                <s1-input-text [control]="formGroup.controls.childEntities"
                    [label]="('form.child_entity' | translate) + ' *'"
                    [readonly]="viewMode"
                    [type]="'number'"
                    [min]="0"
                    [max]="10">
                    <ng-container errors
                        *ngIf="utils.checkIsTouched(formGroup.controls.childEntities)">
                        <div *ngIf="formGroup.get('childEntities').errors"
                            class="invalid-feedback d-block">
                            {{ 'form_errors.required_and_value_max_10' | translate }}
                        </div>
                    </ng-container>
                </s1-input-text>
            </div>
            <ng-container *ngIf="!processing.multiselect.entityElements; else entityPropertiesTemplate">
                <div class="col-lg-4">
                    <s1-input-select [control]="formGroup.controls.defaultElement"
                        [label]="('form.element_default' | translate) + (mandatoryDefault ? ' *' : '')"
                        [itemsList]="multiselectItems.entityElements"
                        [bindValue]="'label'"
                        [readonly]="viewMode || !(formGroup.controls.entity.value)">
                        <ng-container errors
                            *ngIf="utils.checkIsTouched(formGroup.controls.defaultElement)">
                            <div *ngIf="formGroup.get('defaultElement').errors?.required"
                                class="invalid-feedback d-block">
                                {{ 'form_errors.required' | translate }}
                            </div>
                        </ng-container>
                    </s1-input-select>
                </div>
                <div class="col-lg-4">
                    <s1-input-text [control]="formGroup.controls.defaultValue"
                        [label]="('form.default_value' | translate) + (mandatoryDefault ? ' *' : '')"
                        [readonly]="viewMode || !(formGroup.controls.entity.value)">
                        <ng-container errors
                            *ngIf="utils.checkIsTouched(formGroup.controls.defaultValue)">
                            <div *ngIf="formGroup.get('defaultValue').errors?.required"
                                class="invalid-feedback d-block">
                                {{ 'form_errors.required' | translate }}
                            </div>
                            <div *ngIf="formGroup.get('defaultValue').errors?.pattern"
                                class="invalid-feedback d-block">
                                {{ 'form_errors.pipe_pattern_full' | translate }}
                            </div>
                        </ng-container>
                    </s1-input-text>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- ELEMENT - ENTITY RELATIONSHIP -->
    <app-form-header class="text-center mt-4"
        [header]="'elements.entity.entity_relationship'"
        [subHeader]="'elements.entity.entity_relationship_subheader'"></app-form-header>
    <div [ngClass]="{'disabled': viewMode}" class="row d-flex justify-content-center radio-sect">
        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" value="M"
                [formControl]="formGroup.controls.relationship"
                (change)="manageRelationshipSelection('M')">
            <label class="form-check-label">1-M</label>
        </div>

        <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" value="1"
                [formControl]="formGroup.controls.relationship"
                (change)="manageRelationshipSelection('1')">
            <label class="form-check-label">1-1</label>
        </div>
    </div>

    <!-- PROPERTY CHECKBOXES -->
    <div class="row d-flex justify-content-center checkboxes-sect mt-4">
        <div class="col-lg-2 d-flex justify-content-end align-items-center" id="mandatory"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.mandatory' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.mandatory.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.mandatory.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center" id="searchable"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.searchable' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.searchable.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.searchable.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-start align-items-center" id="key"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.key' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.key.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.key.setValue($event)"></s1-button-check>
        </div>
    </div>
</ng-template>

<!-- ENTITY ELEMENT COMPILING (INSTANCE) -->
<ng-template #elementCompiling>
    <div class="row d-flex justify-content-center" *ngIf="loadingComplete">
        <tabset #tabSet class="col-lg-12">
            <tab *ngFor="let childEntity of childEntities; let i = index" [heading]="childEntity" id="tab">
                <!-- Element deletable only if newly added -->
                <ng-template tabHeading *ngIf="i >= childEntitiesNumber && !viewMode">
                    {{ i + 1 }}
                    <i *ngIf="(i + 1) > 1" class="fa fa-trash-alt ml-2 text-danger" (click)="deleteEntityElement(i)"></i>
                </ng-template>
                <!-- ENTITY TAB ELEMENTS -->
                <ng-container *ngIf="entityElements.length >= 1; else noElementsTemplate">
                    <div id="elements-container">
                        <div class="row">
                            <div class="el" [ngClass]="setEntityChildElement(element).type === ElementType.RICHTEXT ? 'col-lg-12' : 'col-lg-6'"
                                *ngFor="let element of entityElements[i]; let elI = index">
                                <app-element-box
                                    [element]="setEntityChildElement(element)"
                                    [elementIdx]="elI"
                                    [viewMode]="viewMode"
                                    [compiling]="true"
                                    (elementValueUpd)="setChildEntitiesControls($event, i, elI)">
                                </app-element-box>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </tab>
            <s1-button right
                *ngIf="!viewMode"
                id="add-tab"
                [type]="S1ButtonType.New"
                [onlyIcon]="true"
                (onClick)="addEntityElement()">
            </s1-button>
        </tabset>
    </div>
</ng-template>

<!-- NO ELEMENTS TEMPLATE -->
<ng-template #noElementsTemplate>
    <div class="w-100 text-center mt-4 mb-2">
        <h4>{{ 'instances.management.no_entities' | translate }}</h4>
    </div>
</ng-template>

<!-- LOADING TEMPLATES -->
<ng-template #elseTemplate>
    <div class="d-flex align-items-center justify-content-center my-5">
        <div class="ball-pulse">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</ng-template>

<ng-template #entityPropertiesTemplate>
    <div class="col-lg-8 ball-pulse d-flex justify-content-center">
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>
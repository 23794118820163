import { Component, OnInit } from '@angular/core';

import { UserblockService } from './userblock.service';
import { AuthService } from '@app/core/services/auth.service';
import { LoggedUserInfo } from '@app/core/interfaces/admin.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {
    
    loggedUserInfo: LoggedUserInfo;
    userName: string;
    
    
    constructor(
        private userblockService: UserblockService,
        private authService: AuthService,
        private translate: TranslateService
    ) {
        this.loggedUserInfo = this.authService.getLoggedUserInfoFromLocalStorage();
        this.userName = this.loggedUserInfo?.accessToken
            ? JSON.parse(window.atob(this.loggedUserInfo.accessToken.split('.')[1])).sub
            : this.translate.instant('base.user');
    }

    ngOnInit() {
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

}

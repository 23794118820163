<form [formGroup]="formGroup" class="headerform" [ngClass]="{'compiling': compiling}">
    <!-- SINGLE CHECK ELEMENT INFORMATION -->
    <app-form-header [ngClass]="compiling ? 'text-left' : 'text-center'"
        [header]="compiling ? '' : 'entities.management.element_properties'"
        [subHeader]="(compiling && !mandatory) ? '' : 'form.required_note'"></app-form-header>
    <ng-container [ngTemplateOutlet]="compiling ? elementCompiling : elementCreation"></ng-container>
</form>

<!-- NUMBER ELEMENT CREATION (ENTITY) -->
<ng-template #elementCreation>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.name"
                [label]="('form.name' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.name)">
                    <div *ngIf="formGroup.get('name').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.label"
                [label]="('form.label' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.label)">
                    <div *ngIf="formGroup.get('label').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.order"
                [label]="('form.order' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="0"
                [max]="100">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.order)">
                    <div *ngIf="formGroup.get('order').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>

    <div class="row d-flex justify-content-center mt-2">
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.intPart"
                [label]="('form.int_part' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="0"
                [max]="10">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.intPart)">
                    <div *ngIf="formGroup.get('intPart').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max_10' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.floatPart"
                [label]="('form.float_part' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="0"
                [max]="5">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.floatPart)">
                    <div *ngIf="formGroup.get('floatPart').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max_5' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>

    <div class="row d-flex justify-content-center checkboxes-sect mt-4">
        <div class="col-lg-2 d-flex justify-content-end align-items-center" id="positiveNumbers"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.positive_numbers' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.positiveNumbers.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.positiveNumbers.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center" id="mandatory"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.mandatory' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.mandatory.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.mandatory.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center" id="searchable"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.searchable' | translate }}</label>
            <s1-button-check [check]="formGroup.controls.searchable.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.searchable.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-start align-items-center" id="key"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.key' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.key.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.key.setValue($event)"></s1-button-check>
        </div>
    </div>
</ng-template>

<!-- NUMBER ELEMENT COMPILING (INSTANCE) -->
<ng-template #elementCompiling>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-12" >
            <s1-input-text [type]="'number'"
                [control]="formGroup.get('compiling')['controls'].inputValue"
                [label]="(formGroup.get('label').value) + (mandatory ? ' *' : '')"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.get('compiling')['controls'].inputValue)">
                    <div *ngIf="formGroup.get('compiling')['controls'].inputValue.errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                    <div *ngIf="formGroup.get('compiling')['controls'].inputValue.errors?.pattern"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.float_number_pattern' | translate : { minus: (positiveNumbers ? '(-)' : ''), intPart, decimalPart } }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>
</ng-template>
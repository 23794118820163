import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators-2';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    valForm: UntypedFormGroup;
    passwordForm: UntypedFormGroup;

    constructor(public settings: SettingsService, fb: UntypedFormBuilder) {

        const password = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9]{6,10}$')]));
        const certainPassword = new UntypedFormControl('', [Validators.required, CustomValidators.equalTo(password)]);

        this.passwordForm = fb.group({
            password,
            certainPassword
        });

        this.valForm = fb.group({
            email: [null, Validators.compose([Validators.required, Validators.email])],
            accountagreed: [null, Validators.required],
            passwordGroup: this.passwordForm
        });
    }

    submitForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.valForm?.controls) {
            this.valForm?.controls[c].markAsTouched();
        }
        for (let c in this.passwordForm?.controls) {
            this.passwordForm?.controls[c].markAsTouched();
        }

        if (this.valForm.valid) {
            console.log('Valid!');
            console.log(value);
        }
    }

    ngOnInit() {
    }

}

import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { LoginData } from '../../../../app/core/classes/loginData.module';

import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import swal from 'sweetalert2';
import { S1UIService } from '@app/s1';
import { LoggedUserInfo } from '@app/core/interfaces/admin.interface';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;
  dataResponse: LoggedUserInfo = null;
  loggedUserInfoLocalStorageKey = 'loggedUserInfo';

  // params from path
  paramId;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;

  constructor(
    public settings: SettingsService,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private ui: S1UIService
  ) {

    // get text message
    //this.errorTranslation =  this.translate.get('error');
    this.translate.get('error').subscribe((text) => {
      this.errorTranslation = text;
    });

    //this.msgTranslation =  this.translate.get('msg');
    this.translate.get('msg').subscribe((text) => {
      this.msgTranslation = text;
    });

    this.paramId = this.route.params.subscribe(params => {
      const id = params['id']; // (+) converts string 'id' to a number
      if (id != null && this.msgTranslation) { this.errorMessage = this.msgTranslation.logout_ok; }
    });

    this.loginForm = this.formBuilder.group({
      'username': [null, Validators.required],
      'password': [null, Validators.required]
    });

  }

  onSubmit(): void {
    this.ui.showSpinner();
    const lData = new LoginData(this.loginForm.value.username, this.loginForm.value.password);
    this.authService.login(lData).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          this.dataResponse = Object.assign({}, response.data);
          localStorage.setItem(this.loggedUserInfoLocalStorageKey, JSON.stringify(this.dataResponse));
          this.ui.closeSpinner();
          this.router.navigate(['/home']);
        } else {
          localStorage.clear();
          this.ui.closeSpinner();
          swal.fire(this.msgTranslation.error, this.settings.manageErrorMsg(response.outcome), "error");
        }
      },
      (error) => {
        this.ui.closeSpinner();
        this.logger.log("Error", error, 200);
        this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
        swal.fire(this.msgTranslation.error, this.errorMessage, "error");
      }
    );
  }

  ngOnInit() { }

}

import { FormGroup } from "@angular/forms";

export enum ElementType {
  TEXT = 1,
  DATE = 2,
  CHECK = 3,
  RADIOLIST = 4,
  CHECKLIST = 5,
  EMAIL = 6,
  LINK = 7,
  NUMBER = 8,
  RICHTEXT = 9,
  LIST = 10,
  MULTILIST = 11,
  ENTITY = 12
}

export const ElementTypes = [ // Labels will be automatically translated when added to the multiselect
  {
    "id": 1,
    "name": "elements.types.text"
  },
  {
    "id": 2,
    "name": "elements.types.date"
  },
  {
    "id": 3,
    "name": "elements.types.single_check"
  },
  {
    "id": 4,
    "name": "elements.types.radio_list"
  },
  {
    "id": 5,
    "name": "elements.types.check_list"
  },
  {
    "id": 6,
    "name": "elements.types.email"
  },
  {
    "id": 7,
    "name": "elements.types.link"
  },
  {
    "id": 8,
    "name": "elements.types.numeric"
  },
  {
    "id": 9,
    "name": "elements.types.richtext"
  },
  {
    "id": 10,
    "name": "elements.types.simple_list"
  },
  {
    "id": 11,
    "name": "elements.types.multi_list"
  },
  {
    "id": 12,
    "name": "elements.types.entity"
  }
]

export interface Element {
  id: number;
  type: string;
  data: TextElement | DateElement | CheckElement | RadiolistElement | ChecklistElement | EmailElement | LinkElement | NumberElement | RichtextElement | ListElement | MultilistElement | EntityElement;
}

/** Interface that represents the element object on entity creation */
export interface CreationElement {
  type: number;
  formGroup: FormGroup;
}

// ---------- ELEMENT TYPES MAPPING --------- //

export interface TextElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  maxNumber: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
  sequence?: string;
}

export interface DateElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  format: string;
  default: string;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface CheckElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface RadiolistElement {
  type?: string;
  name: string;
  radioNumber: number;
  labels: string[];
  values: string[];
  order: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface ChecklistElement {
  type?: string;
  name: string;
  checknumber: number;
  labels: string[];
  values: string[];
  order: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface EmailElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface LinkElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface NumberElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  intpart: number;
  decimalpart: number;
  positivenumbers: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface RichtextElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  maxNumber: number;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface ListElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  listValues: string;
  entity: string;
  entityElKey: string;
  entityElLabel: string;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface MultilistElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  listValues: string;
  entity: string;
  entityElKey: string;
  entityElLabel: string;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

export interface EntityElement {
  type?: string;
  name: string;
  label: string;
  order: number;
  entity: string
  childEntities: number;
  defaultElement: string;
  defaultValue: string;
  relationship: string;
  mandatory: boolean;
  searchable: boolean;
  key: boolean;
}

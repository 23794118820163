import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CreationElement } from "../interfaces/element.interface";

@Injectable()
export class UtilsService {

  constructor(
    private router: Router
  ) { }

  /** Method that checks if the page was opened in view mode */
  isViewMode(pageTypeAction: any, viewAction: any): boolean {
    return pageTypeAction === viewAction;
  }

  /** Method that checks if a certain control was already touched */
  checkIsTouched(control: AbstractControl): boolean {
    return control?.touched;
  }

  /** Method that navigates to the required previous path passed as param */
  navigateBack(previousPath: string): void {
    this.router.navigate([previousPath]);
  }

  /** Method that checks the validity of a form depending on its formGroup and its related elements */
  checkFormValidity(formGroup: FormGroup, elements: CreationElement[]): boolean {
    return formGroup.valid
      && elements.length >= 1
      && elements.every(form => form && form.formGroup.valid ? form.formGroup.valid : false);
  }

  /** Method that checks whether a max value is defined for an input (of course it has to be > 0) */
  checkValidMaxValue(maxValue: number): boolean {
    return maxValue && maxValue > 0; 
  }

}

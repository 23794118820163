import { UserGroup, Permission, Configuration } from './core.interface';
export enum LogLevel {
  ALL = 100,
  DEBUG = 200,
  INFO = 300,
  PROD = 400,
}

export enum SelectionType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum State {
  ALL = 2,
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum BlocksState {
  ALL = 2,
  WITHOUT_BLOCKS = 0,
  WITH_BLOCKS = 1,
}

export interface LoggedUserInfo {
  token?: string;
  accessToken?: string;
  name?: string;
  surname?: string;
  namesurname?: string;
  enabled?: boolean;
  role?: string;
  admin?: boolean;
  groups?: UserGroup[];
  permissions?: Permission[];
  configuration?: Configuration;
}

export interface RequestBody {
  pageNo?: number;
  pageSize?: number;
  sortBy?: string;
  sortDir?: string;
  paginable?: boolean;
  search?: string;
  searchEntity?: string;
  exportType?: string; // TODO: Rimuovere dopo implementazione json rivisto
}

export interface EntityEnum { }

export enum PossibleActionsType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICIATE = 'DUPLICIATE',
  TOGGLE_STATE = 'TOGGLE_STATE',
}

export interface PossibleActions {
  edit?: boolean;
  delete?: boolean;
  duplicate?: boolean;
  disassociate?: boolean;
}

export interface CellConfiguration {
  title?: string;
  name?: string;
  key?: string;
  onChip?: boolean;
}

export interface SweetAlertMsg {
  loadingText?: string;
  creationText?: string;
  updatingText?: string;
  errorText?: string;
  firstError?: string;
  secondError?: string;
  warning?: string;
}

export interface ElementState {
  value: number;
  name: string;
}

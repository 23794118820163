import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
  selector: 'app-element-type-email',
  templateUrl: './element-type-email.component.html',
  styleUrls: ['./element-type-email.component.scss']
})
export class ElementTypeEmailComponent implements OnInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  formGroup: FormGroup;
  mandatory: boolean;

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.formGroup = this.elementFg;

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.mandatory = this.formGroup.get('mandatory').value;

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: ['', this.mandatory ? [Validators.required, Validators.email] : Validators.email]
        }));
      }
    }

    this.valueUpdate.emit(this.formGroup);

    this.formGroup.valueChanges.subscribe(() => {
      this.valueUpdate.emit(this.formGroup);
    });
  }

}

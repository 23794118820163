<div class="input-group" [ngClass]="{'mb-2': hasMargin}">
  <div class="input-group-prepend" *ngIf="label">
      <span class="input-group-text">{{ label | translate }}</span>
  </div>
  <ng-content></ng-content>
  <div class="input-group-append" *ngIf="hasAction">
      <ng-content select="[action]"></ng-content>
  </div>
  <ng-content select="[errors]"></ng-content>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
  selector: 'app-element-type-number',
  templateUrl: './element-type-number.component.html',
  styleUrls: ['./element-type-number.component.scss']
})
export class ElementTypeNumberComponent implements OnInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  formGroup: FormGroup;
  mandatory: boolean;
  positiveNumbers: boolean;
  intPart: number;
  decimalPart: number;

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      intPart: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
      floatPart: [0, [Validators.required, Validators.min(0), Validators.max(5)]],
      positiveNumbers: [false, Validators.required],
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.formGroup = this.elementFg;

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.mandatory = this.formGroup.get('mandatory').value;
        this.positiveNumbers = this.formGroup.get('positiveNumbers').value;
        this.intPart = this.formGroup.get('intPart').value;
        this.decimalPart = this.formGroup.get('floatPart').value;
        

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: [null, this.mandatory
            ? [Validators.required, Validators.pattern(`^${this.positiveNumbers ? '-?' : ''}\\d{${this.intPart >= 1 ? 1 : 0},${this.intPart}}(\\.\\d{${this.decimalPart >= 1 ? 1 : 0},${this.decimalPart}})?$`)]
            : [Validators.pattern(`^${this.positiveNumbers ? '-?' : ''}\\d{${this.intPart >= 1 ? 1 : 0},${this.intPart}}(\\.\\d{${this.decimalPart >= 1 ? 1 : 0},${this.decimalPart}})?$`)]
          ]
        }));
      }
    }

    this.valueUpdate.emit(this.formGroup);

    this.formGroup.valueChanges.subscribe(() => {
      this.valueUpdate.emit(this.formGroup);
    });
  }

}

<form [formGroup]="formGroup" class="headerform" [ngClass]="{'compiling': compiling}">
    <!-- SINGLE CHECK ELEMENT INFORMATION -->
    <app-form-header [ngClass]="compiling ? 'text-left' : 'text-center'"
        [header]="compiling ? '' : 'entities.management.element_properties'"
        [subHeader]="(compiling && mandatory) ? 'instances.management.value_select' : (compiling && !mandatory) ? '' : 'form.required_note'"></app-form-header>
    <ng-container [ngTemplateOutlet]="compiling ? elementCompiling : elementCreation"></ng-container>
</form>

<!-- RADIO LIST ELEMENT CREATION (ENTITY) -->
<ng-template #elementCreation>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.name"
                [label]="('form.name' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.name)">
                    <div *ngIf="formGroup.get('name').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.radioNumber"
                [label]="('form.radio_number' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="1"
                [max]="100">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.radioNumber)">
                    <div *ngIf="formGroup.get('radioNumber').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.order"
                [label]="('form.order' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="0"
                [max]="100">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.order)">
                    <div *ngIf="formGroup.get('order').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>

    <!-- LABELS INPUTS DEPENDING ON RADIONUMBER FIELD -->
    <app-form-header class="text-center mt-4"
        [header]="'entities.management.element_labels'"
        [subHeader]="'elements.list_radio.labels_subheader'"></app-form-header>
    <div *ngIf="formGroup.get('labels')['controls']" class="row d-flex justify-content-center">
        <div *ngFor="let labelCheck of formGroup.get('labels')['controls']; let i = index" class="col-lg-3">
            <s1-input-text [control]="labelCheck"
                [label]="('form.label' | translate) + ' ' + (i + 1) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(labelCheck)">
                    <div *ngIf="labelCheck.errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>

    <!-- VALUES INPUTS DEPENDING ON RADIONUMBER FIELD -->
    <app-form-header class="text-center mt-4"
        [header]="'entities.management.element_values'"
        [subHeader]="'elements.list_radio.values_subheader'"></app-form-header>
    <div *ngIf="formGroup.get('values')['controls']" class="row d-flex justify-content-center">
        <div *ngFor="let valueCheck of formGroup.get('values')['controls']; let i = index" class="col-lg-3">
            <s1-input-text [control]="valueCheck"
                [label]="('form.value' | translate) + ' ' + (i + 1) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(valueCheck)">
                    <div *ngIf="valueCheck.errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>

    <div class="row d-flex justify-content-center checkboxes-sect mt-4">
        <div class="col-lg-2 d-flex justify-content-end align-items-center" id="mandatory"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.mandatory' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.mandatory.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.mandatory.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center" id="searchable"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.searchable' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.searchable.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.searchable.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-start align-items-center" id="key"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.key' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.key.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.key.setValue($event)"></s1-button-check>
        </div>
    </div>
</ng-template>

<!-- RADIO LIST ELEMENT COMPILING (INSTANCE) -->
<ng-template #elementCompiling>
    <div class="row d-flex justify-content-around">
        <div [ngClass]="{'disabled': viewMode}" class="col-lg-12 d-flex justify-content-center radio-sect"
            *ngFor="let label of formGroup.controls.labels.value; let i = index">
            <div class="col-lg-12">
                <div class="form-check form-check-inline mb-2">
                    <label class="form-check-label mr-2">{{ label }}</label>
                    <input type="radio" class="form-check-input" [value]="formGroup.controls.values.value[i]" [formControl]="formGroup.get('compiling')['controls'].inputValue">
                </div>
            </div>
        </div>
    </div>
</ng-template>
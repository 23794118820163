import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreationElement, ElementType } from '@app/core/interfaces/element.interface';
import { IS1InputSelectItem, S1ButtonType } from '@app/s1';

@Component({
  selector: 'app-element-box',
  templateUrl: './element-box.component.html',
  styleUrls: ['./element-box.component.scss']
})
export class ElementBoxComponent implements OnInit {

  @Input() viewMode: boolean = false;
  @Input() elementIdx: number;
  @Input() elementTypes?: IS1InputSelectItem[] = [];
  @Input() element?: CreationElement;
  @Input() compiling: boolean = false;

  @Output() elementValueUpd = new EventEmitter<CreationElement>();
  @Output() elementRemove = new EventEmitter<number>();

  S1ButtonType = S1ButtonType;
  ElementType = ElementType;
  expanded = false;

  formGroup: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.formGroup = this.formBuilder.group({
      type: [null]
    });
  }

  ngOnInit(): void {
    if (this.element) {
      this.formGroup.get('type').setValue(this.element.type);
      this.expanded = true;
    }
  }

  removeElement(): void {
    this.elementRemove.emit(this.elementIdx);
  }

  onElementExpand(): void {
    this.expanded = !this.expanded;
  }

  manageValueUpd(event?: FormGroup): void {
    this.elementValueUpd.emit({type: this.formGroup.get('type').value, formGroup: event ? event : this.formGroup});
  }

}

<s1-input-label [label]="hideLabel ? '' : label" [hasAction]="hasAction">
  <textarea class="form-control"
    [ngClass]="{'is-invalid' : !checkIsValid() }"
    [formControl]="ctrl"
    [placeholder]="getPlaceholder()"
    [readonly]="readonly"
    [maxlength]="max"
    [rows]="rows ? rows : null"></textarea>
  <ng-container action *ngIf="hasAction">
    <ng-content select="[action]"></ng-content>
  </ng-container>
  <ng-container errors>
    <ng-content select="[errors]"></ng-content>
  </ng-container>
</s1-input-label>

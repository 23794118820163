<form *ngIf="componentReady(); else elseTemplate" [formGroup]="formGroup" class="headerform" [ngClass]="{'compiling': compiling}">
    <!-- LIST ELEMENT INFORMATION -->
    <app-form-header [ngClass]="compiling ? 'text-left' : 'text-center'"
        [header]="compiling ? '' : 'entities.management.element_properties'"
        [subHeader]="(compiling && mandatory) ? elementType === ElementType.LIST ? 'instances.management.list_select_value' : 'instances.management.list_select_multi' : (compiling && !mandatory) ? '' : 'form.required_note'"></app-form-header>
    <ng-container [ngTemplateOutlet]="compiling ? elementCompiling : elementCreation"></ng-container>
</form>

<!-- LIST ELEMENT CREATION (ENTITY) -->
<ng-template #elementCreation>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.name"
                [label]="('form.name' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.name)">
                    <div *ngIf="formGroup.get('name').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.label"
                [label]="('form.label' | translate) + ' *'"
                [readonly]="viewMode">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.label)">
                    <div *ngIf="formGroup.get('label').errors?.required"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
        <div class="col-lg-3">
            <s1-input-text [control]="formGroup.controls.order"
                [label]="('form.order' | translate) + ' *'"
                [readonly]="viewMode"
                [type]="'number'"
                [min]="0"
                [max]="100">
                <ng-container errors
                    *ngIf="utils.checkIsTouched(formGroup.controls.order)">
                    <div *ngIf="formGroup.get('order').errors"
                        class="invalid-feedback d-block">
                        {{ 'form_errors.required_and_value_max' | translate }}
                    </div>
                </ng-container>
            </s1-input-text>
        </div>
    </div>

    <!-- VALUES MANAGEMENT -->
    <div *ngIf="!formGroup.controls.entity.value">
        <app-form-header class="text-center mt-4"
            [header]="'elements.list_simple.values_management'"
            [subHeader]="'elements.list_simple.values_management_subheader'"></app-form-header>
        <div class="row d-flex justify-content-center">
            <s1-editable-list [readonly]="viewMode"
                [valueRegex]="valuesRegex"
                [regexError]="'form_errors.pipe_pattern'"
                [defaultValues]="elementFg ? valueList : null"
                (onListChange)="valueListChange($event)"></s1-editable-list>
        </div>
    </div>

    <!-- ENTITY ATTRIBUTES MANAGEMENT -->
    <app-form-header class="text-center mt-4"
        [header]="'elements.list_simple.entity_management'"
        [subHeader]="'elements.list_simple.entity_management_subheader'"></app-form-header>
    <div class="row d-flex justify-content-center">
        <div class="col-lg-4">
            <s1-input-select [control]="formGroup.controls.entity"
                [label]="('form.entity' | translate) + ' *'"
                [itemsList]="multiselectItems.entities"
                [readonly]="viewMode || valueList.length >= 1"
                (onSelect)="loadEntityProperties(true)"
                (onClear)="entityClear()">
            </s1-input-select>
        </div>
        <ng-container *ngIf="!processing.multiselect.entityElements; else entityPropertiesTemplate">
            <div class="col-lg-4">
                <s1-input-select [control]="formGroup.controls.entityElKey"
                    [label]="('form.key' | translate) + ' *'"
                    [itemsList]="multiselectItems.entityKeys"
                    [bindValue]="'label'"
                    [readonly]="viewMode || !(formGroup.controls.entity.value) || valueList.length >= 1">
                </s1-input-select>
            </div>
            <div class="col-lg-4">
                <s1-input-select [control]="formGroup.controls.entityElLabel"
                    [label]="('form.value' | translate) + ' *'"
                    [itemsList]="multiselectItems.entityKeys"
                    [bindValue]="'label'"
                    [readonly]="viewMode || !(formGroup.controls.entity.value) || valueList.length >= 1">
                </s1-input-select>
            </div>
        </ng-container>
    </div>

    <!-- PROPERTY CHECKBOXES -->
    <div class="row d-flex justify-content-center checkboxes-sect mt-4">
        <div class="col-lg-2 d-flex justify-content-end align-items-center" id="mandatory"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.mandatory' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.mandatory.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.mandatory.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-center align-items-center" id="searchable"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.searchable' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.searchable.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.searchable.setValue($event)"></s1-button-check>
        </div>
        <div class="col-lg-2 d-flex justify-content-start align-items-center" id="key"
            [ngClass]="{'disabled': viewMode}">
            <label class="mr-2">{{ 'form.key' | translate }}</label>
            <s1-button-check
                [check]="formGroup.controls.key.value"
                [disabled]="viewMode"
                (checkChange)="formGroup.controls.key.setValue($event)"></s1-button-check>
        </div>
    </div>
</ng-template>

<!-- LIST ELEMENT COMPILING (INSTANCE) -->
<ng-template #elementCompiling>
    <div class="row d-flex justify-content-center" *ngIf="formGroup.get('compiling')">
        <div class="col-lg-12" >
            <s1-input-select [control]="formGroup.get('compiling')['controls'].inputValue"
                [label]="(formGroup.get('label').value) + (mandatory ? ' *' : '')"
                [itemsList]="multiselectItems.instanceEntityProperties"
                [bindValue]="'code'"
                [multiple]="elementType === ElementType.LIST ? false : true"
                [readonly]="viewMode">
            </s1-input-select>
        </div>
    </div>
</ng-template>

<!-- LOADING TEMPLATES -->
<ng-template #elseTemplate>
    <div class="d-flex align-items-center justify-content-center my-5">
        <div class="ball-pulse">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</ng-template>

<ng-template #entityPropertiesTemplate>
    <div class="col-lg-8 ball-pulse d-flex justify-content-center">
        <div></div>
        <div></div>
        <div></div>
    </div>
</ng-template>
export enum InstanceAction {
    NEW_INSTANCE = 'newInstance',
    EDIT_INSTANCE = 'editInstance',
    VIEW_INSTANCE = 'viewInstance',
    DELETE_INSTANCE = 'deleteInstance'
}

export interface Instance {
    id?: number;
    idEntity?: number;
    key: string;
    data: InstanceCreationElement[];
}

/** Interface that represents the element object on interface creation (when the element is valorized depending on entity properties) */
export interface InstanceCreationElement {
    name: string;
    inputValue: string | number | InstanceCreationElement[];
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
  selector: 'app-element-type-list-check',
  templateUrl: './element-type-list-check.component.html',
  styleUrls: ['./element-type-list-check.component.scss']
})
export class ElementTypeListCheckComponent implements OnInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  formGroup: FormGroup;
  mandatory: boolean;

  checkValues: string[] = [];

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      checkNumber: [1, [Validators.required, Validators.min(1), Validators.max(100)]],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      labels: this.formBuilder.array([
        ['', Validators.required]
      ]),
      values: this.formBuilder.array([
        ['', Validators.required]
      ]),
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.formGroup = this.elementFg;

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.mandatory = this.formGroup.get('mandatory').value;

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: [[], this.mandatory ? Validators.required : null]
        }));
      }
    }

    this.valueUpdate.emit(this.formGroup);
    
    this.formGroup.get('checkNumber').valueChanges.subscribe((value: number) => {
      this.manageCheckNumberChange(+value);
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.valueUpdate.emit(this.formGroup);
    });
  }

  /** Method fired on check selection, that assigns a value to the check */
  manageCheckSelection(event: boolean, i: number) {
    const values = this.formGroup.get('values').value;
    const inputValue = this.formGroup.get('compiling').get('inputValue');

    event ? this.checkValues.push(values[i]) : this.checkValues.splice(this.checkValues.indexOf(values[i]), 1);

    inputValue.setValue(this.checkValues);
  }

  // --------- PRIVATE METHODS --------- //

  /** Method that manages what happens on radio number input change, in order to create labels and values inputs according to the current required amount */
  private manageCheckNumberChange(value: number): void {
    if (!value) {
      this.formGroup.get('checkNumber').patchValue(1);
      
      this.clearDynamicForms();
      
      this.createDynamicFormControls();
    } else {
      this.clearDynamicForms();
      
      [...Array(value)].forEach(() => {
        this.createDynamicFormControls();
      });
    }
  }

  /** Method that clears the dynamic form controls */
  private clearDynamicForms(): void {
    (this.formGroup.get('labels') as FormArray).clear();
    (this.formGroup.get('values') as FormArray).clear();
  }

  /** Method that assigns new formcontrols to the dynamic controls */
  private createDynamicFormControls(): void {
    (this.formGroup.get('labels') as FormArray).push(this.formBuilder.control('', [Validators.required]));
    (this.formGroup.get('values') as FormArray).push(this.formBuilder.control('', [Validators.required]));
  }

}

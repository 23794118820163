import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface IS1InputSelectItem {
  id?: number;
  code: string | number;
  label: string;
}

@Component({
  selector: 's1-input-select',
  templateUrl: './s1-input-select.component.html',
  styleUrls: ['./s1-input-select.component.scss']
})
export class S1InputSelect implements OnInit {

  @Input() control: AbstractControl;
  @Input() label = '';
  @Input() hasAction = false;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() clearable = true;
  @Input() itemsList: IS1InputSelectItem[];
  @Input() bindValue = 'code';
  @Input() ngLabelTmpRef: TemplateRef<any>;
  @Input() ngOptionTmpRef: TemplateRef<any>;
  @Input() async = false; // Loads select asynchronously when huge amounts of data are passed

  @Output() onSelect = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  checkIsValid(): boolean {
    return this.control?.valid || !this.control?.touched;
  }

  elementSelected(event: any): void {
    if (event) {
      this.onSelect.emit(event);
    }
  }

  elementCleared(): void {
    this.onClear.emit();
  }

  get ctrl() {
    return this.control as FormControl;
  }

}

import { Injectable } from "@angular/core";
import { Instance, InstanceAction, InstanceCreationElement } from "../interfaces/instance.interface";
import { S1HttpClientService } from "@app/s1";
import { RequestBody } from "../interfaces/admin.interface";
import { Observable } from "rxjs";
import * as ENDPOINT from '../constants/endPoint.js';
import { CreationElement, ElementType } from "../interfaces/element.interface";
import { formatDate } from "@angular/common";
import { FormBuilder } from "@angular/forms";
import {
    checkStringIsPipeList,
    createArrayFromPipeList,
    getEndPointWithParams,
    createPipedlistFromList,
    saveObjectFromCreationElement,
    getInstanceCreationElementFromKeyValue
} from '../../../assets/ts/utils';

@Injectable({providedIn: 'root'})
export class InstancesService {

    private typeActionInstance: InstanceAction;
    private itemToEdit: Instance;
    private selectedEntityId: number;

    constructor(
        private s1HttpService: S1HttpClientService,
        private formBuilder: FormBuilder
    ) { }

    /* GETTER AND SETTER TYPE ACTION */
    setTypeAction(typeActionInstance: InstanceAction): void {
        this.typeActionInstance = typeActionInstance;
    }
    getTypeAction(): InstanceAction {
        return this.typeActionInstance;
    }

    /* GETTER AND SETTER ITEM TO EDIT */
    setItemToEdit(item: any): void { // any because the list of instances is customized depending on its values
        this.itemToEdit = item;
    }
    getItemToEdit(): any { // any because the list of instances is customized depending on its values
        return this.itemToEdit;
    }

    /* GETTER AND SETTER ITEM TO EDIT */
    setSelectedEntityId(entityId: number) {
        this.selectedEntityId = entityId;
    }
    getSelectedEntityId(): number {
        return this.selectedEntityId;
    }

    /* ACTIONS */
    elementList(reqBody?: RequestBody): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.ADMIN_INSTANCES, {...reqBody, exportType: 'G'});
        return this.s1HttpService.get(endPoint, false);
    }

    instancesListFromEntityId(reqBody: RequestBody, idEntity: number): Observable<any> {
        const endPoint = getEndPointWithParams(`${ENDPOINT.ADMIN_LIST_INSTANCES}/${idEntity}`, reqBody);
        return this.s1HttpService.get(endPoint, false);
    }

    createInstance(instance: Instance): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.ADMIN_INSTANCE, { exportType: 'G' });
        return this.s1HttpService.post(endPoint, instance);
    }

    updateInstance(instance: Instance): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.ADMIN_INSTANCE, { exportType: 'G' });
        return this.s1HttpService.putWithBody(endPoint, instance);
    }

    loadInstanceDetail(idInstance: number): Observable<any> {
        const endPoint = getEndPointWithParams(`${ENDPOINT.ADMIN_INSTANCE}/${idInstance}`, { exportType: 'G' });
        return this.s1HttpService.get(endPoint, false);
    }

    /* INSTANCE DATA MANAGEMENT */

    manageInstanceElementsFormGroups(instanceEntityElements: CreationElement[], instanceElementsData: InstanceCreationElement[]): CreationElement[] {
        instanceEntityElements.forEach((iEl) => {
            const instanceElementKey = Object.keys(instanceElementsData[instanceElementsData.findIndex(el => el[iEl.formGroup.value.name] || el[iEl.formGroup.value.name] === null)])[0];
            iEl.formGroup.addControl('compiling', this.formBuilder.group({
                name: instanceElementKey,
                inputValue: (checkStringIsPipeList(instanceElementsData[instanceElementsData.findIndex(el => el[iEl.formGroup.value.name] || el[iEl.formGroup.value.name] === null)][instanceElementKey]) || iEl.type === ElementType.MULTILIST)
                    ? [createArrayFromPipeList(instanceElementsData[instanceElementsData.findIndex(el => el[iEl.formGroup.value.name] || el[iEl.formGroup.value.name] === null)][instanceElementKey])]
                    : iEl.type === ElementType.ENTITY
                    ? [getInstanceCreationElementFromKeyValue(instanceElementsData[instanceElementsData.findIndex(el => el[iEl.formGroup.value.name] || el[iEl.formGroup.value.name] === null)][instanceElementKey])]
                    : instanceElementsData[instanceElementsData.findIndex(el => el[iEl.formGroup.value.name] || el[iEl.formGroup.value.name] === null)][instanceElementKey]
            }));
        });

        return instanceEntityElements;
    }

    /** Method that creates the data to be saved in an instance entry. Different inputValues are applied depending on the type */
    createInstanceData(instanceElements: CreationElement[]): InstanceCreationElement[] {
        const instanceData: InstanceCreationElement[] = [];

        instanceElements.forEach((el, i: number) => {
            const { name, inputValue } = el.formGroup.get('compiling').value;
            let value: string | number | InstanceCreationElement[];
            
            switch (el.type) {
                case ElementType.DATE:
                    value = !! inputValue ? formatDate(inputValue, 'dd/MM/yyyy', 'it') : null;
                break;
                case ElementType.CHECK:
                    value = !inputValue || inputValue ? inputValue : null;
                break;
                case ElementType.CHECKLIST:
                case ElementType.MULTILIST:
                    value = inputValue && inputValue.length ? createPipedlistFromList(inputValue) : null;
                break;
                case ElementType.NUMBER:
                    value = +inputValue;
                break;
                case ElementType.ENTITY:
                    value = inputValue.map(ar => ar.map(v => saveObjectFromCreationElement(v)));
                break;
                default:
                    value = !!inputValue ? inputValue : null;
                break;
            }

            const data: InstanceCreationElement = {
                name,
                inputValue: value
            }
            instanceData.push(saveObjectFromCreationElement(data));
        });

        return instanceData;
    }
    
}

export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
  NULL = ''
}

export enum OrderByEntities {
  USERNAME = 'username',
  ID = 'id',
  NAME = 'name'
}

export enum OrderByInstances {
  ID_ENTITY = 'idEntity',
  KEY = 'key'
}

export enum ServerJobState {
  PENDING = 'PENDING',
  READY = 'READY',
  OK = 'OK',
  KO = 'KO',
}

export enum ServerJobStateId {
  ALL = 0,
  OK = 1,
  KO = 2,
  PENDING = 3,
  READY = 4,
}

export enum ServerJobService { }

export enum ConfigurationThresholdTimeUnit { }

export enum ConfigurationStockUnit { }

export enum SectionName { }

export enum Multiselect {
  ENTITIES = 'entities',
  ENTITY_ELEMENTS = 'entityElements',
  INSTANCE_ENTITY_PROPERTIES = 'instanceEntityProperties'
}

export interface MultiselectItems {
  elements?: MultiselectItem[];
  entities?: MultiselectItem[];
  entityKeys?: MultiselectItem[];
  entityLabels?: MultiselectItem[];
  entityElements?: MultiselectItem[];
  elementDefault?: MultiselectItem[];
  instanceEntityProperties?: MultiselectItem[]; // Not connected to an enum because valorised on instance creation
}

export interface MultiselectItem {
  id?: number;
  code?: number | string;
  label?: string;
  productCode?: string;
  productDescription?: string;
  colour?: string;
  name?: string;
  description?: string;
}

export interface SortableItem {
  id: number;
  value: string;
  metadata?: any;
}

export interface Company {
  id?: number;
  businessName?: string;
  address?: string;
  active?: boolean;
  tsCreated?: number;
}

export interface Cooperative {
  idCooperative: number;
  businessName: string;
}

export interface UserGroup {
  idGroup: number;
  codeRole: string;
  name: string;
}

export interface Permission {
  idPermission: number;
  idGroup: number;
  roleName: string;
  namePermission: string;
  code?: string;
}

export interface Configuration {
  productThresholdTimeUnit?: string;
}

export interface Description {
  id: number;
  description: string;
}

export interface CodeName {
  id?: number;
  code?: string | number;
  name?: string;
}

export interface PossibleAction {
  canEdit?: boolean;
  canDelete?: boolean;
}

export interface ServerJob {
  dateTime?: number;
  error?: string;
  id?: number;
  service?: ServerJobService;
  status?: ServerJobState;
}

export interface SessionMetadata {
  idSite: number;
  idRole: number;
}

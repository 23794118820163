import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';

registerLocaleData(localeIT);

@Pipe({
  name: 's1Currency'
})
export class S1CurrencyPipe extends CurrencyPipe implements PipeTransform {

  transform(value: number): null
  transform(value: number): string {

    return super.transform(value, 'EUR', 'symbol', '1.2-2', 'it')

  }

}

<div class="list-container mb-2">
    <!-- LIST VALUE INSERT -->
    <div *ngIf="!readonly" class="value-input d-flex align-items-center">
        <s1-input-text [control]="formGroup.get('inputValue')"
            [label]="('form.value' | translate)"
            [type]="'text'"
            [hasMargin]="false"
            (onEnter)="addValue()">
        </s1-input-text>
        <s1-button right
            class="ml-2"
            [type]="S1ButtonType.New"
            [onlyIcon]="true"
            [disabled]="!formGroup.get('inputValue').value || formGroup.get('inputValue').errors"
            (onClick)="addValue()">
        </s1-button>
    </div>
    <ng-container errors
        *ngIf="utils.checkIsTouched(formGroup.controls.inputValue)">
        <div *ngIf="formGroup.get('inputValue').errors"
            class="invalid-feedback d-block text-center">
            {{ regexError ? (regexError | translate) : ('form_errors.required' | translate) }}
        </div>
    </ng-container>

    <!-- DRAGGABLE VALUES SECTION -->
    <div *ngIf="values.length"
        class="values-list mt-1"
        [ngClass]="{'disabled': readonly}"
        (dndDrop)="onDrop($event)" dndDropzone>
        <div *ngFor="let value of values; let j = index"
            class="value" id="{{ j }}"
            (dndStart)="onDragStart(j)" dndDraggable>
            <div class="info">
                <i *ngIf="!readonly" class="fa fa-minus-circle ml-2 text-danger delete" (click)="removeValue(value)"></i>
                <span>{{ value }}</span>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
  selector: 'app-element-type-text',
  templateUrl: './element-type-text.component.html',
  styleUrls: ['./element-type-text.component.scss']
})
export class ElementTypeTextComponent implements OnInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  formGroup: FormGroup;
  mandatory: boolean;
  maxCharacters: number;
  sequenceAvailable: boolean = true;
  sequenceValueAvailable: boolean;

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      maxcharacters: [10, Validators.required],
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.sequenceAvailable = !!this.elementFg.get('sequence');
      this.formGroup = this.elementFg;

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.sequenceValueAvailable = this.sequenceAvailable && this.formGroup.get('sequence').value;

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: ['', this.mandatory ? Validators.required : null]
        }));

        if (!this.sequenceValueAvailable) {
          this.mandatory = this.formGroup.get('mandatory').value;
          this.maxCharacters = this.formGroup.get('maxcharacters').value;
        } else {
          this.formGroup.get('compiling').get('inputValue').patchValue(this.formGroup.get('sequence').value);
        }
      }
    }

    /** Sequence control is added only with sequenceAvailable = true (the entity contains it or the user is creating an entity) */
    if (this.sequenceAvailable && !this.compiling) {
      this.formGroup.addControl('sequence', new FormControl(''));
    }

    this.valueUpdate.emit(this.formGroup);

    this.formGroup.valueChanges.subscribe(() => {
      if (!this.utils.checkValidMaxValue(this.formGroup.get('maxcharacters').value)) {
        this.formGroup.get('mandatory').patchValue(false, { emitEvent: false });
      }

      this.valueUpdate.emit(this.formGroup);
    });
  }

  /** Method fired on "key" checkbox change, that sets a new value for the input and on deselection clears the sequence (if available) */
  onKeyChange(event: boolean): void {
    this.formGroup.get('key').setValue(event);
    
    if (this.sequenceAvailable && !event) {
      this.formGroup.get('sequence').setValue('');
    }
  }

}

<button [type]="isSubmit ? 'submit' : 'button'"
    class="btn p-2 shadow-sm"
    [ngClass]="outline ? styles[type]?.classOutline : styles[type]?.class"
    (click)="click()"
    [disabled]="disabled"
    [title]="(label ? label : styles[type]?.label) | translate"
    [hidden]="hidden">
    <i *ngIf="type != s1ButtonType.Next && !onlyText"
        class="{{ icon ? icon : styles[type]?.icon }}"></i>
    <span *ngIf="!onlyIcon"
        [ngClass]="{'ml-2': !onlyText}">
        {{ (label ? label : styles[type]?.label) | translate }}
    </span>
    <i *ngIf="type == s1ButtonType.Next && !onlyText"
        class="{{ icon ? icon : styles[type]?.icon }}"></i>
</button>

import { Injectable } from '@angular/core';

const appTheme = require('../../shared/styles/themes/theme-app.scss');

@Injectable()
export class ThemesService {

    defaultTheme = appTheme;

    styleTag: any;

    constructor() {
        this.createStyle();
        this.injectStylesheet(this.defaultTheme);
    }

    injectStylesheet(css) {
        this.styleTag.innerHTML = css.default;
    }

    getDefaultTheme() {
        return this.defaultTheme;
    }

    // ---------- PRIVATE METHODS --------- //

    private createStyle() {
        const head = document.head || document.getElementsByTagName('head')[0];
        this.styleTag = document.createElement('style');
        this.styleTag.type = 'text/css';
        this.styleTag.id = 'appthemes';
        head.appendChild(this.styleTag);
    }

}

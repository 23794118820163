import { Injectable } from "@angular/core";
import { Entity, EntityAction } from "../interfaces/entity.interface";
import { S1HttpClientService } from "@app/s1";
import { Observable } from "rxjs";
import * as ENDPOINT from '../constants/endPoint.js';
import { RequestBody } from "../interfaces/admin.interface";
import { getEndPointWithParams } from '../../../assets/ts/utils';
import { CheckElement, ChecklistElement, CreationElement, DateElement, Element, ElementType, EmailElement, EntityElement, LinkElement, ListElement, MultilistElement, NumberElement, RadiolistElement, RichtextElement, TextElement } from "../interfaces/element.interface";
import { FormBuilder, Validators } from "@angular/forms";

@Injectable({ providedIn: 'root' })
export class EntitiesService {

    typeActionEntity: EntityAction;
    itemToEdit: Entity;

    constructor(
        private s1HttpService: S1HttpClientService,
        private formBuilder: FormBuilder
    ) { }

    /* GETTER AND SETTER TYPE ACTION */
    setTypeAction(typeActionEntity: EntityAction): void {
        this.typeActionEntity = typeActionEntity;
    }
    getTypeAction(): EntityAction {
        return this.typeActionEntity;
    }

    /* GETTER AND SETTER ITEM TO EDIT */
    setItemToEdit(item: Entity): void {
        this.itemToEdit = item;
    }
    getItemToEdit(): Entity {
        return this.itemToEdit;
    }

    /* ACTIONS */
    elementList(reqBody?: RequestBody): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.ADMIN_ENTITIES, reqBody);
        return this.s1HttpService.get(endPoint, false);
    }

    createEntity(entity: Entity): Observable<any> {
        const endPoint = ENDPOINT.ADMIN_ENTITY;
        return this.s1HttpService.post(endPoint, entity);
    }

    updateEntity(entity: Entity): Observable<any> {
        const endPoint = ENDPOINT.ADMIN_ENTITY;
        return this.s1HttpService.putWithBody(endPoint, entity);
    }

    loadEntityDetail(idEntity: number, typeG: boolean = false): Observable<any> {
        const endPoint = typeG ? `${ENDPOINT.ADMIN_ENTITY}/${idEntity}?orderType=G` : `${ENDPOINT.ADMIN_ENTITY}/${idEntity}`;
        return this.s1HttpService.get(endPoint, false);
    }

    /* ENTITY DATA MANAGEMENT */

    /**
     * Method that generates the creation elements with their type and formGroup depending on the received entity elements
     * 
     * When elements are needed even if null or empty there's an || validation in the check
     */
    manageEntityElementsFormGroups(entityElements: Element[]): CreationElement[] {
        const entityCreationElements: CreationElement[] = [];

        entityElements.forEach(el => {
            entityCreationElements.push({
                type: ElementType[el.type],
                formGroup: this.formBuilder.group({ // Basic properties are added directly, otherwise a property is added only when available in the element
                    name: [el.data.name, Validators.required],
                    ...(el.data['label']) && { label: [el.data['label'], Validators.required] }, 
                    ...(el.data['radioNumber'] || el.data['maxNumber'] === 0) && { radioNumber: [+el.data['radioNumber'], Validators.required] }, 
                    ...(el.data['checknumber'] || el.data['maxNumber'] === 0) && { checkNumber: [+el.data['checknumber'], Validators.required] }, 
                    order: [el.data.order, Validators.required],
                    ...(el.data['maxNumber'] || el.data['maxNumber'] === 0) && { maxcharacters: [+el.data['maxNumber'], Validators.required] },
                    ...(el.data['format']) && { format: [el.data['format'], Validators.required] },
                    ...(el.data['default']) && { defaultFormat: [el.data['default'], Validators.required] },
                    ...(el.data['labels']) && { labels: this.formBuilder.array(el.data['labels'].map((l: string) => [l, Validators.required])) },
                    ...(el.data['values']) && { values: this.formBuilder.array(el.data['values'].map((v: string) => [v, Validators.required])) },
                    ...(el.data['intpart'] || el.data['intpart'] === 0) && { intPart: [+el.data['intpart'], Validators.required] },
                    ...(el.data['decimalpart'] || el.data['decimalpart'] === 0) && { floatPart: [+el.data['decimalpart'], Validators.required] },
                    ...(el.data['positivenumbers'] || el.data['positivenumbers'] === false) && { positiveNumbers: [+el.data['positivenumbers'], Validators.required] },
                    ...(el.data['listValues']) && { listValues: [el.data['listValues'].map((lv: string) => lv), Validators.required] },
                    ...(el.data['entity'] || el.data['entity'] === null) && { entity: [el.data['entity'], Validators.required] },
                    ...(el.data['entityElKey'] || el.data['entityElKey'] === null) && { entityElKey: [el.data['entityElKey'], Validators.required] },
                    ...(el.data['entityElLabel'] || el.data['entityElLabel'] === null) && { entityElLabel: [el.data['entityElLabel'], Validators.required] },
                    ...(el.data['childEntities'] || el.data['childEntities'] === 0) && { childEntities: [el.data['childEntities'], Validators.required] },
                    ...(el.data['defaultElement'] || el.data['defaultElement'] === null) && { defaultElement: [el.data['defaultElement'], Validators.required] },
                    ...(el.data['defaultValue'] || el.data['defaultValue'] === null) && { defaultValue: [el.data['defaultValue'], Validators.required] },
                    ...(el.data['relationship']) && { relationship: [el.data['relationship'], Validators.required] },
                    mandatory: [el.data.mandatory, Validators.required],
                    searchable: [el.data.searchable, Validators.required],
                    key: [el.data.key, Validators.required],
                    ...(el.data['sequence'] || el.data['sequence'] === '') && { sequence: [el.data['sequence']] },
                })
            });
        });

        return entityCreationElements;
    }
    
    /** Method that creates the specific data object depending on the type of the element, setting the values using what's defined in the formGroup */
    createEntityData(entityElements: CreationElement[]): Element[] {
        const entityData: Element[] = [];
    
        entityElements.forEach((el, i: number) => {
            switch(el.type) {
                case ElementType.TEXT: {
                    const { name, label, order, maxcharacters, mandatory, searchable, key, sequence } = el.formGroup.value;
                    const data: TextElement | RichtextElement = {
                        name,
                        label,
                        order,
                        maxNumber: maxcharacters,
                        mandatory,
                        searchable,
                        key,
                        sequence
                    };
                    entityData.push({ id: i, type: 'TEXT', data });
                    break;
                }
                case ElementType.RICHTEXT: {
                    const { name, label, order, maxcharacters, mandatory, searchable, key } = el.formGroup.value;
                    const data: TextElement | RichtextElement = {
                        name,
                        label,
                        order,
                        maxNumber: maxcharacters,
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({ id: i, type: 'RICHTEXT', data });
                    break;
                }
                case ElementType.DATE: {
                    const { name, label, order, format, defaultFormat, mandatory, searchable, key } = el.formGroup.value;
                    const data: DateElement = {
                        name,
                        label,
                        order,
                        format,
                        default: defaultFormat,
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({ id: i, type: 'DATE', data });
                    break;
                }
                case ElementType.RADIOLIST: {
                    const { name, radioNumber, order, labels, values, mandatory, searchable, key } = el.formGroup.value;
                    const data: RadiolistElement = {
                        name,
                        radioNumber,
                        order,
                        labels: labels.map((label: string) => label),
                        values: values.map((value: string) => value), 
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({ id: i, type: 'RADIOLIST', data });
                    break;
                }
                case ElementType.CHECKLIST: {
                    const { name, checkNumber, order, labels, values, mandatory, searchable, key } = el.formGroup.value;
                    const data: ChecklistElement = {
                        name,
                        checknumber: checkNumber,
                        order,
                        labels: labels.map((label: string) => label),
                        values: values.map((value: string) => value), 
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({ id: i, type: 'CHECKLIST', data });
                    break;
                }
                case ElementType.NUMBER: {
                    const { name, label, order, intPart, floatPart, positiveNumbers, mandatory, searchable, key } = el.formGroup.value;
                    const data: NumberElement = {
                        name,
                        label,
                        order,
                        intpart: intPart,
                        decimalpart: floatPart,
                        positivenumbers: positiveNumbers, 
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({ id: i, type: 'NUMBER', data });
                    break;
                }
                case ElementType.CHECK:
                case ElementType.EMAIL:
                case ElementType.LINK: {
                    const { name, label, order, mandatory, searchable, key } = el.formGroup.value;
                    const data: CheckElement | EmailElement | LinkElement = {
                        name,
                        label,
                        order,
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({
                        id: i, 
                        type: el.type === ElementType.CHECK ? 'CHECK' : el.type === ElementType.EMAIL ? 'EMAIL' : 'LINK',
                        data
                    });
                    break;
                }
                case ElementType.LIST:
                case ElementType.MULTILIST: {
                    const { name, label, order, listValues, entity, entityElKey, entityElLabel, mandatory, searchable, key } = el.formGroup.value;
                    const data: ListElement | MultilistElement = {
                        name,
                        label,
                        order,
                        listValues,
                        entity,
                        entityElKey,
                        entityElLabel,
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({
                        id: i,
                        type: el.type === ElementType.LIST ? 'LIST' : 'MULTILIST',
                        data
                    });
                    break;
                }
                case ElementType.ENTITY: {
                    const { name, label, order, childEntities, entity, defaultElement, defaultValue, relationship, mandatory, searchable, key } = el.formGroup.value;
                    const data: EntityElement = {
                        name,
                        label,
                        order,
                        childEntities: +childEntities,
                        entity,
                        defaultElement,
                        defaultValue,
                        relationship,
                        mandatory,
                        searchable,
                        key
                    };
                    entityData.push({ id: i, type: 'ENTITY', data});
                    break;
                }
            }
        });

        return entityData;
    }

}

import { Injectable } from '@angular/core';
import { LogLevel } from '@app/core/interfaces/admin.interface';
import { LoggingService } from '@app/core/services/log.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import swal, { SweetAlertResult } from 'sweetalert2';

export enum S1PopUpType {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  Question = 'question'
}

@Injectable({
  providedIn: 'root'
})
export class S1UIService {

  constructor(
    private translate: TranslateService,
    private toasterService: ToastrService,
    private settings: SettingsService,
    private logger: LoggingService
  ) { }

  // Spinner
  showSpinner() {
    swal.fire({
      title: this.translate.instant('s1.swal.loading'),
      html: `
        <div class="card-body loader-demo d-flex align-items-center justify-content-center">
          <div class="ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>`,
      showConfirmButton: false,
      allowOutsideClick: false
    });
  }

  closeSpinner() {
    swal.close();
  }

  // Popup
  showPopup(type: S1PopUpType, title?: string, text?: string) {

    swal.fire(this.translate.instant(title), this.translate.instant(text)  , type);

  }

  showPopupNoPermission() {
    this.showPopup(S1PopUpType.Warning, 's1.swal.attention',  's1.swal.noPermission');
  }

  showHTTPErrorPopup(error: any) {

    let errorTitle = 's1.error';
    let errorMessage = 'genericError';

    if (error.statusText) {
      errorMessage = error.statusText;
    }

    if (error.status) {

      errorTitle = '' + error.status;

      switch (error.status) {
        case 401: // Unauthorized
        case 403: // Forbidden
          errorMessage = this.translate.instant('APIErrors.' + error.status);
          break;
      }

    }

    this.showPopup(S1PopUpType.Error, errorTitle, errorMessage);

  }

  showDialogPopup(title: string): Promise<SweetAlertResult> {

    return swal.fire({
      title: this.translate.instant('s1.swal.attention'),
      text: this.translate.instant(title),
      icon: S1PopUpType.Question,
      showCloseButton: true,
      showCancelButton: true,
      customClass: {
        cancelButton: 'btn btn-xs btn-danger'
      }
    });

  }

  showDialogSuccess(title: string) {
    swal.fire({
      icon: 'success',
      title,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      timer: 1500,
    });
  }

  showDialogError(title: string) {
    swal.fire({
      icon: 'error',
      title,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: false,
      timer: 1500,
    });
  }

  // SUCCESS DIALOG

  showDialogCreateSuccess() {
    this.showDialogSuccess(this.translate.instant('modal.create_success'));
  }

  showDialogEditSuccess() {
    this.showDialogSuccess(this.translate.instant('modal.edit_success'));
  }

  showDialogGenerateSuccess() {
    this.showDialogSuccess(this.translate.instant('modal.generate_success'));
  }

  showDialogMoveSuccess() {
    this.showDialogSuccess(this.translate.instant('modal.move_success'));
  }

  // ERROR DIALOG

  showDialogCreateError() {
    this.showDialogError(this.translate.instant('modal.create_error'));
  }

  showDialogEditError() {
    this.showDialogError(this.translate.instant('modal.edit_error'));
  }

  // TOAST
  showSuccessToast(text: string) {
    this.toasterService.success(this.translate.instant(text), this.translate.instant('s1.toast.success'));
  }
  showErrorToast(text: string) {
    this.toasterService.error(text, this.translate.instant('s1.toast.error'));
  }

  manageError(error: Error, errMsg: string): void {
    this.showErrorToast(this.settings.manageErrorMsg(error));
    this.logger.log(errMsg, error, LogLevel.DEBUG);
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from "@app/core/services/utils.service";
import { S1ButtonType } from "../s1-button/s1-button.component";

@Component({
  selector: 's1-editable-list',
  templateUrl: './s1-editable-list.component.html',
  styleUrls: ['./s1-editable-list.component.scss']
})
export class S1EditableList implements OnInit {

  @Input() readonly: boolean;
  @Input() valueRegex: string;
  @Input() regexError: string;
  @Input() defaultValues: string[];

  @Output() onListChange = new EventEmitter<string[]>();

  S1ButtonType = S1ButtonType;

  formGroup: FormGroup;

  values: string[] = [];
  dragValueIndex: number;
 
  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.defaultValues) {
      this.values = this.defaultValues;
    }

    this.formGroup = this.formBuilder.group({
      inputValue: ['']
    });

    if (this.valueRegex) {
      this.formGroup.get('inputValue').setValidators([Validators.pattern(this.valueRegex)]);
    }
  }

  addValue(): void {
    this.values.push(this.formGroup.get('inputValue').value);
    this.formGroup.reset();
    this.onListChange.emit(this.values);
  }

  removeValue(value: string): void {
    this.values.splice(this.values.indexOf(value), 1);
    this.onListChange.emit(this.values);
  }

  // ---------- DRAG ACTIONS -------- //

  /** Method fired on drag action start in order to store the original element */
  onDragStart(index: number): void {
    this.dragValueIndex = index;
  }

  /** Method fired on drop that switches the position of the original value with the one in the new position */
  onDrop(dropAction) {
    [this.values[this.dragValueIndex], this.values[+dropAction.event.target.id]]= [this.values[+dropAction.event.target.id], this.values[this.dragValueIndex]];
    this.onListChange.emit(this.values);
  }
  
}
        
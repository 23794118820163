import { RequestBody } from "@app/core/interfaces/admin.interface";
import { Instance, InstanceCreationElement } from "@app/core/interfaces/instance.interface";

export function getEndPointWithParams(endPoint: string, params: RequestBody): string {
    for (const [i, [key, value]] of Object.entries(params).entries()) {
        value !== null ? endPoint += `${i === 0 ? '?' : '&'}${key}=${value}` : endPoint = endPoint
    }
    return endPoint;
}

export function setEndOfTheDay(date: Date): Date {
    let d = new Date(date);
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    d.setMilliseconds(999);
    return d;
}

/** Method that checks whether a string is composed as value-pipe list */
export function checkStringIsPipeList(value: string): boolean {
    return new RegExp('^[^|]*(\\|\\s*[^|]*){1,}$').test(value);
}

/** Method that creates an array form a pipedlist string value */
export function createArrayFromPipeList(list: string): string[] | number[] {
    return list ? list.split('|') : null;
}

/** Method that returns an id-name object list from an original piped list ('el1 | Value1', 'el2 | Value2'...) */
export function createObjListFromPipeList(list: any[]): any {
    return list.map(v => v.split('|').reduce((agg: string, key: string) => { return { id: agg.trim(), name: key.trim() } }));
}

/** Method that creates a piped string list from an array of number/string values. String values are trimmed before piping */
export function  createPipedlistFromList(list: string[] | number[]): string {
    return list.map((v: string | number) => typeof v === 'string' ? v.trim() : v).join('|');
}

/** Method that creates an object with specific key: value pair depending on the name and inputValue set on creation element */
export function saveObjectFromCreationElement(creationElement: InstanceCreationElement): any {
    return { [creationElement.name]: creationElement.inputValue !== null ? creationElement.inputValue : null };
}

export function getInstanceCreationElementFromKeyValue(keyValues: any[]): InstanceCreationElement[] {
    return keyValues.map(kV => kV.map(v => { return { name: Object.keys(v)[0], inputValue: Object.values(v)[0] } }));
}

/** Method that configures each instance element on view / edit in order to be an object as follows: { name, inputValue } */
export function getCompilingFormGroupFromInstanceData(instanceElement: InstanceCreationElement): InstanceCreationElement {
    const val = Object.values(instanceElement)[0];

    const name = Object.keys(instanceElement)[0];
    const inputValue = Array.isArray(val)
        ? val.map(v => {
            return v.map(x => {
                for (const [key, value] of Object.entries(x)) {
                    return { name: key, inputValue: value }
                }  
            })            
        })
        : val;

    return { name, inputValue };
}

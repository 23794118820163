const HeaderMain = {
    text: 'Features',
    translate: 'menu.functionalities',
    heading: true
}

const Home = {
    text: 'Home',
    translate: 'menu.home',
    link: '/home',
    icon: 'fas fa-home'
};

const Entities = {
    text: 'Entities',
    translate: 'menu.entities',
    link: '/entities',
    icon: 'fas fa-file-code'
}

const Instances = {
    text: 'Instances',
    translate: 'menu.instances',
    link: '/instances',
    icon: 'fas fa-pencil' 
}

export const menu = [
    HeaderMain,
    Home,
    Entities,
    Instances
];

import { Multiselect, MultiselectItem } from './../interfaces/core.interface';
import { LoggingService } from '@app/core/services/log.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as ENDPOINT from "../constants/endPoint.js";
import { S1HttpClientService } from '@app/s1';
import { LogLevel } from '../interfaces/admin.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: "root" })
export class MultiselectService {

  constructor(
    private logger: LoggingService,
    private s1HttpService: S1HttpClientService,
    private translate: TranslateService
  ) { }

  /* ACTIONS */
  loadMultiselect(multiselectType: Multiselect, param?: string | string[]): Observable<any> {
    let endPoint: string;
    switch (multiselectType) {
      case Multiselect.ENTITIES:
        endPoint = ENDPOINT.MULTI_ENTITIES;
      break;
      case Multiselect.ENTITY_ELEMENTS:
        endPoint = ENDPOINT.MULTI_ENTITY_ELEMENTS;
      break;
      case Multiselect.INSTANCE_ENTITY_PROPERTIES:
        endPoint = ENDPOINT.MULTI_INSTANCE;
      break;
    }

    /** When an array of arams is passed all the entries are added to the request url */
    const url = param ? (Array.isArray(param) ? `${endPoint}/${param.map(p => p).slice().join('/')}` : `${endPoint}/${param}`) : endPoint;

    return this.s1HttpService.get(url, false);
  }

  /** Method that configures an object in order to make it usable in a multiselect */
  getItemsFromData(data, translateLabel?: boolean): MultiselectItem[] {
    return data?.map((d, idx) => {
      return {
        code: d.id ? d.id : d.code ? d.code : idx,
        label: d.name ? (translateLabel ? this.translate.instant(d.name) : d.name)
          : (d.description ? (translateLabel ? this.translate.instant(d.description) : d.description)
          : d.label ? d.label : null)
      };
    });
  }

  /** Method hta loads values that has to be displayed in a multiselect, creating the object as required by the component */
  loadMultiselectItems(multiselectType: Multiselect): Promise<MultiselectItem[]> {
    return new Promise((resolve, reject) => {
        this.loadMultiselect(multiselectType).subscribe(
          (response) => {
            this.logger.log("[SUCCESS] Caricamento multiselect", response, LogLevel.DEBUG);
            const { data } = response;
            const multiselectValues = this.getItemsFromData(data);
            resolve(multiselectValues);
          },
          (error) => {
            this.logger.log("[ERROR] Caricamento multiselect", error, LogLevel.DEBUG);
            reject();
          }
        );
      }
    );
  }

}
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogLevel } from '@app/core/interfaces/admin.interface';
import { Multiselect, MultiselectItems } from '@app/core/interfaces/core.interface';
import { CreationElement, ElementType } from '@app/core/interfaces/element.interface';
import { Entity } from '@app/core/interfaces/entity.interface';
import { InstanceAction, InstanceCreationElement } from '@app/core/interfaces/instance.interface';
import { EntitiesService } from '@app/core/services/entities.service';
import { InstancesService } from '@app/core/services/instances.service';
import { LoggingService } from '@app/core/services/log.service';
import { MultiselectService } from '@app/core/services/multiselect.service';
import { UtilsService } from '@app/core/services/utils.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1UIService } from '@app/s1';
import { TranslateService } from '@ngx-translate/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import * as _ from 'lodash';

@Component({
  selector: 'app-element-type-entity',
  templateUrl: './element-type-entity.component.html',
  styleUrls: ['./element-type-entity.component.scss']
})
export class ElementTypeEntityComponent implements OnInit, AfterViewInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  @ViewChild('tabSet') tabset: TabsetComponent;

  S1ButtonType = S1ButtonType;
  ElementType = ElementType;

  formGroup: FormGroup;
  valueRegex = '^[^|]*';
  mandatory: boolean;
  mandatoryDefault: boolean = false;
  originalEntities: string[] = [];
  childEntities: string[] = [];
  entityDetail: Entity;
  entityElements: any[] = [];
  childEntitiesControls: any[] = [];
  loadingComplete: boolean = false;
  childEntitiesNumber: number = 0;

  multiselectItems: MultiselectItems = {
    entities: [],
    entityElements: []
  }

  processing = {
    multiselect: {
      entities: false,
      entityElements: false
    },
    entityDetails: false
  }

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder,
    private multiselectService: MultiselectService,
    private logger: LoggingService,
    private ui: S1UIService,
    private settingService: SettingsService,
    private entitiesService: EntitiesService,
    private translate: TranslateService,
    private instancesService: InstancesService
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      childEntities: [0, [Validators.required, Validators.min(0), Validators.max(10)]],
      entity: [null, Validators.required],
      defaultElement: [null],
      defaultValue: [''],
      relationship: ['', Validators.required],
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadMultiselect(Multiselect.ENTITIES, null, true);
  }

  async ngAfterViewInit(): Promise<void> {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.formGroup = this.elementFg;
      this.loadEntityProperties();
      this.manageDefaultChecks();

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.mandatory = this.formGroup.get('mandatory').value;

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: this.formBuilder.array
        }));

        await this.manageChildEntities();

        this.loadingComplete = true;
      }
    }

    this.valueUpdate.emit(this.formGroup);

    this.formGroup.valueChanges.subscribe(() => {
      this.valueUpdate.emit(this.formGroup);
    });

    this.formGroup.get('childEntities').valueChanges.subscribe(() => {
      this.manageDefaultChecks();
    })
  }

  componentReady(): boolean {
    return !this.processing.multiselect.entities &&
      !this.processing.entityDetails;
  }

  loadEntityProperties(): void {
    this.loadMultiselect(Multiselect.ENTITY_ELEMENTS, this.formGroup.get('entity').value);
  }

  /** Method fired on entity relationship selection (1-1 / 1-many) that manages the values relative to entity children */
  manageRelationshipSelection(value: string): void {
    const children = this.formGroup.get('childEntities');
    const defaultElement = this.formGroup.get('defaultElement');
    const defaultValue = this.formGroup.get('defaultValue');

    children.reset();
    defaultElement.reset();
    defaultValue.reset();

    switch (value) {
      case '1':
        children.setErrors(null);
        defaultElement.setErrors(null);
        defaultValue.setErrors(null);

        children.setValidators(null);
        defaultElement.setValidators(null);
        defaultValue.setValidators(null);
      break;
      case 'M':
        children.setValidators(Validators.required);
        this.manageDefaultChecks();
      break;
    }
  }

  addEntityElement(originalElement: boolean = false): void {
    if (originalElement) {
      this.childEntities = [...this.childEntities, this.childEntities[0]];
      this.originalEntities = JSON.parse(JSON.stringify(this.childEntities));
    } else {
      this.childEntities.push(this.translate.instant('form.element'));
    }
    
    this.childEntitiesControls.push([]);
    
    this.entityElements.push(_.cloneDeep(this.entityElements[0]));
  }

  /** Method that removes the specified entity element and focuses the second to last tab (before deleting) */
  deleteEntityElement(index: number) {
    this.childEntities.splice(index, 1);
    this.childEntitiesControls.splice(index, 1);
    this.entityElements.splice(index, 1);
    
    const tabs = this.tabset.tabs;

    if (tabs[index].active) {
      tabs[tabs.length - 2].active = true;
    }
  }

  /**
   * Entity element box value must be set dinamically cloning the original formGroup, in order to have a different check for each value.
   * 
   * Children entity formgroup obligatoriness is set due to the mandatory nature of the entries in the original entity
   */
  setEntityChildElement(element: CreationElement): CreationElement {
    const entityMandatory = this.elementFg.get('mandatory').value;
    element.formGroup.get('mandatory').patchValue(entityMandatory, { emitEvent: false });
    return element;
  }

  /** Management of formControls related to child entities, called on tab add/removal and on component creation */
  setChildEntitiesControls(event: CreationElement, elementBoxIdx: number, elementIdx: number): void {
    const values = this.formGroup.get('compiling').get('inputValue') as FormArray;
    const eventValues = event.formGroup.get('compiling').get('inputValue') as FormArray;
    const eventName = event.formGroup.value.name;

    if (!this.childEntitiesControls[elementBoxIdx][elementIdx] || this.childEntitiesControls[elementBoxIdx][elementIdx] !== eventValues) {
      this.childEntitiesControls[elementBoxIdx][elementIdx] = { name: eventName, inputValue: eventValues.value };
    }

    values.setValue(this.childEntitiesControls);
  }

  // ---------- PRIVATE METHODS --------- //

  private loadMultiselect(multiselectType: Multiselect, param?: string, clear: boolean = false): void {
    this.processing.multiselect[multiselectType] = true;
    this.clearMultiselect(clear);

    this.multiselectService.loadMultiselect(multiselectType, param).subscribe(
      (response) => {
        this.logger.log("[SUCCESS] Caricamento multiselect", response, LogLevel.DEBUG);
        const { data } = response;
        this.multiselectItems[multiselectType] = this.multiselectService.getItemsFromData(data.results);
        this.processing.multiselect[multiselectType] = false;
      },
      (error) => {
        this.logger.log("[ERROR] Caricamento multiselect", error, LogLevel.DEBUG);
        this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
        this.processing.multiselect[multiselectType] = false;
      }
    )
  }

  private clearMultiselect(clearValues: boolean = false): void {
    this.multiselectItems.entityElements = [];

    if (clearValues) {
      this.formGroup.get('defaultElement').patchValue(null);
      this.formGroup.get('defaultValue').patchValue(null);
    }
  }

  /** Entity children management that sets tab headers and entity details */
  private async manageChildEntities(): Promise<void> {
    
    await this.loadEntityDetails(this.formGroup.get('entity').value);

    const defaultValue = this.formGroup.get('defaultValue').value;
    const splitValues = defaultValue ? defaultValue.split('|').map((v: string) => v.trim()) : [];

    this.childEntitiesNumber = splitValues.length;

    this.childEntities = defaultValue
      ? splitValues
      : [this.entityDetail.name];

    this.originalEntities = JSON.parse(JSON.stringify(this.childEntities));

    this.childEntities.forEach(() => {
      this.childEntitiesControls.push([]);
    });

    if (this.instancesService.getTypeAction() && this.instancesService.getTypeAction() !== InstanceAction.NEW_INSTANCE) {
      this.manageEntityElements(); 
    }
  }

  /** Method that loads entity element values on edit / view, in order to have pre-populated fields for each entity child element */
  private manageEntityElements(): void {
    const values = this.formGroup.get('compiling').get('inputValue') as FormArray;
    
    values.value.forEach((_: InstanceCreationElement, cI: number) => {
      if (this.entityElements[cI]) {
        this.entityElements[cI].map((el: CreationElement, i: number) => {
          el.formGroup.addControl('compiling', this.formBuilder.group(values.value[cI][i]), { emitEvent: false });
        });
      } else {
        this.addEntityElement(true);
        this.entityElements[cI].map((el: CreationElement, i: number) => {
          el.formGroup.get('compiling').patchValue(values.value[cI][i], { emitEvent: false });
        });
      }
    });
  }

  /** Method that loads the details of the entity connected to the entity element */
  private loadEntityDetails(idEntity: number): Promise<void> {
    this.processing.entityDetails = true;
    return new Promise((res, rej) => {
      this.entitiesService.loadEntityDetail(idEntity).subscribe(
        (response) => {
          this.logger.log("[SUCCESS] Caricamento dettagli entità", response, LogLevel.DEBUG);
          const { data } = response;
          this.entityDetail = JSON.parse(JSON.stringify(data));
          this.entityElements = [this.entitiesService.manageEntityElementsFormGroups(this.entityDetail.data)];
          
          this.processing.entityDetails = false;
          res();
        },
        (error) => {
          this.logger.log("[ERROR] Caricamento dettagli entità", error, LogLevel.DEBUG);
          this.ui.showDialogError(this.translate.instant('modal.detail_error'));
          rej();
        }
      );
    });
  }

  /** Management of default element - value entity properties */
  private manageDefaultChecks(): void {
    const defaultElement = this.formGroup.get('defaultElement');
    const defaultValue = this.formGroup.get('defaultValue');

    const value = +this.formGroup.get('childEntities').value;

    if (value > 0) {
      defaultElement.setValidators(Validators.required);
      defaultValue.setValidators([Validators.required, Validators.pattern(value > 1 ? `${this.valueRegex}(\\|\\s*[^|]*){1,${value - 1}}$` : this.valueRegex)]);
      this.mandatoryDefault = true;
    } else {
      defaultElement.setErrors(null);
      defaultValue.setErrors(null);

      defaultElement.setValidators(null);
      defaultValue.setValidators(null);
      this.mandatoryDefault = false;
    }
  }

}

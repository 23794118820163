<div class="element" [ngClass]="{'compiling': compiling}">
    <div class="d-flex flex-row align-items-center justify-content-between" id="head">
        <div class="d-flex align-items-center">
            <i *ngIf="!viewMode" class="fa fa-trash-alt ml-2 text-danger delete" (click)="removeElement()"></i>
            <div class="title">
                <span>{{ 'form.element' | translate }} <span>{{ (elementIdx + 1) }}</span></span>
                <!-- <span *ngIf="compiling">{{ element.formGroup.controls.name.value }}</span> -->
            </div>
        </div>
        <div class="mr-2">
            <s1-button [type]="expanded ? S1ButtonType.ExpandLess : S1ButtonType.ExpandMore"
                [onlyIcon]="true"
                (onClick)="onElementExpand()">
            </s1-button>
        </div>
    </div>

    <div class="detail-element-outer-container row-on-focus" [ngClass]="{'visible': expanded}">
        <div class="detail-element-inner-container">
            <ng-container [ngTemplateOutlet]="elementManagement"></ng-container>
        </div>
    </div>
</div>

<!-- ELEMENT CONTAINER -->
<ng-template #elementManagement>
    <!-- TYPE SELECTION -->
    <div *ngIf="!compiling" class="type-selection d-flex justify-content-center align-items-center mt-2">
        <app-form-header class="pr-3"
            [header]="'entities.management.element_type'"
            [subHeader]="'entities.management.element_type_subheader'">
        </app-form-header>
        <div class="col-lg-3">
            <s1-input-select [control]="formGroup.controls.type"
                [label]="('form.type' | translate) + ' *'"
                [itemsList]="elementTypes"
                [readonly]="viewMode">
            </s1-input-select>
        </div>
    </div>
    
    <!-- ELEMENT CONFIGURATION -->
    <div class="mt-4" *ngIf="formGroup.controls.type.value">
        <app-element-type-text *ngIf="formGroup.controls.type.value === ElementType.TEXT"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.TEXT ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-text>
        <app-element-type-date *ngIf="formGroup.controls.type.value === ElementType.DATE"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.DATE ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-date>
        <app-element-type-single-check *ngIf="formGroup.controls.type.value === ElementType.CHECK"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.CHECK ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-single-check>
        <app-element-type-list-radio *ngIf="formGroup.controls.type.value === ElementType.RADIOLIST"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.RADIOLIST ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-list-radio>
        <app-element-type-list-check *ngIf="formGroup.controls.type.value === ElementType.CHECKLIST"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.CHECKLIST ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-list-check>
        <app-element-type-email *ngIf="formGroup.controls.type.value === ElementType.EMAIL"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.EMAIL ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-email>
        <app-element-type-link *ngIf="formGroup.controls.type.value === ElementType.LINK"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.LINK ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-link>
        <app-element-type-number *ngIf="formGroup.controls.type.value === ElementType.NUMBER"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.NUMBER ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-number>
        <app-element-type-richtext *ngIf="formGroup.controls.type.value === ElementType.RICHTEXT"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.RICHTEXT ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-richtext>
        <app-element-type-list *ngIf="formGroup.controls.type.value === ElementType.LIST || formGroup.controls.type.value === ElementType.MULTILIST"
            [compiling]="compiling"
            [elementFg]="element && (element.type === ElementType.LIST || element.type === ElementType.MULTILIST) ? element.formGroup : null"
            [viewMode]="viewMode"
            [elementType]="formGroup.controls.type.value"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-list>
        <app-element-type-entity *ngIf="formGroup.controls.type.value === ElementType.ENTITY"
            [compiling]="compiling"
            [elementFg]="element && element.type === ElementType.ENTITY ? element.formGroup : null"
            [viewMode]="viewMode"
            (valueUpdate)="manageValueUpd($event)"></app-element-type-entity>
    </div>
</ng-template>

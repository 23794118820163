import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogLevel } from '@app/core/interfaces/admin.interface';
import { Multiselect, MultiselectItems } from '@app/core/interfaces/core.interface';
import { ElementType } from '@app/core/interfaces/element.interface';
import { LoggingService } from '@app/core/services/log.service';
import { MultiselectService } from '@app/core/services/multiselect.service';
import { UtilsService } from '@app/core/services/utils.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1UIService } from '@app/s1';
import { createObjListFromPipeList } from '../../../../../assets/ts/utils';

@Component({
  selector: 'app-element-type-list',
  templateUrl: './element-type-list.component.html',
  styleUrls: ['./element-type-list.component.scss']
})
export class ElementTypeListComponent implements OnInit, AfterViewInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;
  @Input() elementType: ElementType;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  formGroup: FormGroup;
  valuesRegex = '^[^|]{1,20}\\s*\\|\\s*[^|]{1,50}$';
  valueList: string[] = [];
  mandatory: boolean;

  Multiselect = Multiselect;
  ElementType = ElementType;

  multiselectItems: MultiselectItems = {
    entities: [],
    entityKeys: [],
    entityLabels: [],
    entityElements: [],
    instanceEntityProperties: []
  }

  processing = {
    multiselect: {
      entities: false,
      entityElements: false
    }
  }

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder,
    private multiselectService: MultiselectService,
    private logger: LoggingService,
    private ui: S1UIService,
    private settingService: SettingsService
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      listValues: [[], Validators.required],
      entity: [null, Validators.required],
      entityElKey: [null, Validators.required],
      entityElLabel: [null, Validators.required],
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    await this.loadMultiselect(Multiselect.ENTITIES);
  }

  async ngAfterViewInit(): Promise<void> {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.formGroup = this.elementFg;
      this.valueListChange(this.formGroup.get('listValues').value);

      if (this.formGroup.get('entity').value) {
        await this.loadEntityProperties();
      }

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.mandatory = this.formGroup.get('mandatory').value;

        this.formGroup.get('listValues').value.length ? this.manageListValues() : await this.manageEntityList();

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: [null, this.mandatory ? Validators.required : null]
        }));
      }
    }

    this.valueUpdate.emit(this.formGroup);

    this.formGroup.valueChanges.subscribe(() => {
      this.valueUpdate.emit(this.formGroup);
    });
  }

  componentReady(): boolean {
    return !this.processing.multiselect.entities;
  }

  valueListChange(list: string[]): void {
    this.valueList = list;
    this.formGroup.get('listValues').patchValue(this.valueList);
    this.setErrorsOnAction(1);
  }

  /** Method that loads the properties of the selected entity and fills their specific multiselect elements */
  async loadEntityProperties(fromEntityChange: boolean = false): Promise<void> {
    this.setErrorsOnAction(2);

    if (fromEntityChange) {
      this.formGroup.get('entityElKey').patchValue(null);
      this.formGroup.get('entityElLabel').patchValue(null);
    }

    await this.loadMultiselect(Multiselect.ENTITY_ELEMENTS, this.formGroup.get('entity').value);
  }

  /** Event fired on entity selection clear action */
  entityClear(): void {
    this.clearMultiselect(true);
  }

  // ---------- PRIVATE METHODS --------- //

  private loadMultiselect(multiselectType: Multiselect, param?: string | string[]): Promise<void> {
    this.processing.multiselect[multiselectType] = true;
    this.clearMultiselect();

    return new Promise((resolve, reject) => {
      this.multiselectService.loadMultiselect(multiselectType, param).subscribe(
        (response) => {
          this.logger.log("[SUCCESS] Caricamento multiselect", response, LogLevel.DEBUG);
          const { data } = response;
          
          /** On entity elments creation two lists has to be valorized relative to the entity properties keys and labels */
          if (multiselectType === Multiselect.ENTITY_ELEMENTS) {
            data.results.forEach((el: { name: string, label: string }, i: number) => {
              this.multiselectItems.entityKeys.push({ code: i, label: el.name });
            });
          } else {
            this.multiselectItems[multiselectType] = this.multiselectService.getItemsFromData(data.results);
          }
  
          this.processing.multiselect[multiselectType] = false;
          
          resolve();
        },
        (error) => {
          this.logger.log("[ERROR] Caricamento multiselect", error, LogLevel.DEBUG);
          this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
          this.processing.multiselect[multiselectType] = false;
          
          reject();
        }
      );
    });
  }

  private clearMultiselect(clearValues: boolean = false): void {
    this.multiselectItems.entityElements = [];
    this.multiselectItems.entityKeys = [];

    if (clearValues) {
      this.formGroup.get('entityElKey').patchValue(null);
      this.formGroup.get('entityElLabel').patchValue(null);
    }
  }

  private setErrorsOnAction(action: number): void {
    const entity = this.formGroup.get('entity');
    const elLabel = this.formGroup.get('entityElLabel');
    const elKey = this.formGroup.get('entityElKey');
    const values = this.formGroup.get('listValues');

    switch (action) {
      case 1: // Value add (entities disabled and validators - errors removed)
        values.setValidators(Validators.required);

        entity.setErrors(null);
        elLabel.setErrors(null);
        elKey.setErrors(null);

        entity.setValidators(null);
        elLabel.setValidators(null);
        elKey.setValidators(null);
      break;
      case 2: // Entity selection (values control disabled)
        this.valueList = [];
        values.patchValue([]);
        values.setValidators(null);
        values.setErrors(null);

        entity.setValidators(Validators.required);
        elLabel.setValidators(Validators.required);
        elKey.setValidators(Validators.required);
      break;
    }
  }

  /** On compiling the values received as 'id | value' must be converted to elements that can be managed in an ng-select component */
  private manageListValues(): void {
    const formattedList = createObjListFromPipeList(this.formGroup.get('listValues').value);
    this.multiselectItems.instanceEntityProperties = this.multiselectService.getItemsFromData(formattedList);
  }

  private async manageEntityList(): Promise<void> {
    const entity = this.formGroup.get('entity').value;
    const entityElKey = this.formGroup.get('entityElKey').value;
    const entityElLabel = this.formGroup.get('entityElLabel').value;

    await this.loadMultiselect(Multiselect.INSTANCE_ENTITY_PROPERTIES, [entity, entityElKey, entityElLabel]);
  }

}

<s1-input-label [label]="hideLabel ? '' : label" [hasAction]="hasAction" [hasMargin]="hasMargin">
  <input class="form-control"
    [ngClass]="{'is-invalid' : !checkIsValid() }"
    type="{{ type }}"
    [formControl]="ctrl"
    [placeholder]="getPlaceholder()"
    [min]="min"
    [max]="max"
    [maxlength]="maxlength"
    [readonly]="readonly"
    (change)="enterAction()" />
  <ng-container action *ngIf="hasAction">
    <ng-content select="[action]"></ng-content>
  </ng-container>
  <ng-container errors>
    <ng-content select="[errors]"></ng-content>
  </ng-container>
</s1-input-label>

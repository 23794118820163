import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from '@app/core/services/utils.service';

@Component({
  selector: 'app-element-type-richtext',
  templateUrl: './element-type-richtext.component.html',
  styleUrls: ['./element-type-richtext.component.scss']
})
export class ElementTypeRichtextComponent implements OnInit {

  @Input() compiling?: boolean;
  @Input() viewMode: boolean;
  @Input() elementFg?: FormGroup;

  @Output() valueUpdate = new EventEmitter<FormGroup>();

  formGroup: FormGroup;
  mandatory: boolean;
  maxCharacters: number;

  constructor(
    public utils: UtilsService,
    private formBuilder: FormBuilder
  ) {
    this.formGroup = this.formBuilder.group({
      name: ['', Validators.required],
      label: ['', Validators.required],
      order: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      maxcharacters: [0, [Validators.required]],
      mandatory: [false, Validators.required],
      searchable: [false, Validators.required],
      key: [false, Validators.required]
    });
  }

  ngOnInit(): void {
    /** FormGroup values are set on view / edit, since element values are already defined */
    if (this.elementFg) {
      this.formGroup = this.elementFg;

      // Property added to the form when the element is used as input on instance creation
      if (this.compiling) {
        this.mandatory = this.formGroup.get('mandatory').value;
        this.maxCharacters = this.formGroup.get('maxcharacters').value;

        this.formGroup.addControl('compiling', this.formBuilder.group({
          name: [this.formGroup.get('name').value, Validators.required],
          inputValue: ['', this.mandatory ? Validators.required : null]
        }));
      }
    }
    
    this.valueUpdate.emit(this.formGroup);

    this.formGroup.valueChanges.subscribe(() => {
      if (!this.utils.checkValidMaxValue(this.formGroup.get('maxcharacters').value)) {
        this.formGroup.get('mandatory').patchValue(false, { emitEvent: false });
      }
      
      this.valueUpdate.emit(this.formGroup);
    });
  }

}
